<template>
  <div class="loading" v-if="isLoading">
    <div class="loader">
      <!-- logo色中空的旋转圆环 -->
      <div class="loader-text">Copilot</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 确保在最上层 */
}
.loader {
  position: relative;
  width: 100px; /* 设置圆环的外径 */
  height: 100px; /* 设置圆环的外径 */
  border-radius: 50%; /* 使其成为圆形 */
  overflow: hidden; /* 确保内容不会溢出 */
}

.loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    $logo-color-darkgreen 0deg 60deg,
    $logo-color-blue 60deg 120deg,
    $logo-color-red 120deg 180deg,
    $logo-color-yellow 180deg 240deg,
    $logo-color-purple 240deg 300deg,
    $logo-color-orange 300deg 360deg
  );
  animation: spin 2s linear infinite; /* 设置旋转动画 */
}

.loader::after {
  content: '';
  position: absolute;
  top: 10px; /* 设置中空的圆环厚度 */
  left: 10px; /* 设置中空的圆环厚度 */
  width: 80px; /* 设置内径 */
  height: 80px; /* 设置内径 */
  border-radius: 50%; /* 使其成为圆形 */
  background: white; /* 设置中空部分的背景颜色 */
}
.loader-text {
  position: absolute;
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 使用 transform 使文本完全居中 */
  font-size: 18px; /* 设置字体大小 */
  color: black; /* 设置字体颜色 */
  font-weight: bold; /* 加粗字体 */
  z-index: 1;
}
</style>
